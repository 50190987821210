import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpServiceHelper {
  // TODO: Replace with actual production server
  private strProdServer = 'https://b2c.gulfapps.mx/api';
  private strDevServer = 'https://b2c-gulf-s.gigigoapps.com/api';

  constructor() {}

  baseUrl(endpoint: string): string {
    if (environment.production) {
      return this.strProdServer.concat(endpoint);
    } else {
      return this.strDevServer.concat(endpoint);
    }
  }

  encodeData(model: any) {
    const formData = new FormData();
    // tslint:disable-next-line:forin
    for (const element in model) {
      formData.append(element, model[element]);
    }
    return formData;
  }
}
