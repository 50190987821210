import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { Shell } from '@app/shell/services/shell.service';
import { AuthenticationGuard } from './core/authentication/authentication.guard';

const routes: Routes = [
  Shell.childRoutes([
    { path: 'about', loadChildren: './pages/about/about.module#AboutModule' },
    {
      path: 'points',
      loadChildren: './pages/points/points.module#PointsModule',
      canActivate: [AuthenticationGuard]
    },
    {
      path: 'tickets',
      loadChildren: './pages/tickets/tickets.module#TicketsModule',
      canActivate: [AuthenticationGuard]
    },
    {
      path: 'configuration',
      loadChildren:
        './pages/configuration/configuration.module#ConfigurationModule',
      canActivate: [AuthenticationGuard]
    },
    { path: 'faqs', loadChildren: './pages/faqs/faqs.module#FaqsModule' },
    {
      path: 'pricing',
      loadChildren: './pages/pricing/pricing.module#PricingModule'
    }
  ]),
  { path: 'auth', loadChildren: './pages/auth/auth.module#AuthModule' },
  { path: 'terms', loadChildren: './pages/terms/terms.module#TermsModule' },
  {
    path: 'privacy',
    loadChildren: './pages/privacy/privacy.module#PrivacyModule'
  },

  // Fallback when no prior route is matched
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      useHash: true
    })
  ],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule {}
