import { Component, OnInit, Input } from '@angular/core';
import { LoyaltyfyService } from '../../../../_services/loyaltyfy.service';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'home-promotion',
  templateUrl: './home-promotion.component.html'
})
export class HomePromotionComponent implements OnInit {
  promoImage: SafeResourceUrl;
  promoURL: SafeResourceUrl;
  promoTitle: string;
  fa = {
    arrow: faArrowRight
  };
  constructor(
    private loyaltyfyService: LoyaltyfyService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.loyaltyfyService.getBucketToken().subscribe(
      bucketToken => {
        this.loyaltyfyService
          .getLoyaltyfyUser(bucketToken.data[0].token)
          .subscribe(
            response => {
              this.promoImage = this.sanitizer.bypassSecurityTrustResourceUrl(
                response['data']['promotion-image']
              );
              this.promoURL = this.sanitizer.bypassSecurityTrustResourceUrl(
                response['data']['hub-promotional-uri']
              );
              this.promoTitle = response['data']['promotion-title'];
            },
            error => {
              console.log('error');
              console.log(error);
            }
          );
      },
      error => {
        console.log('error');
        console.log(error);
      }
    );
  }
}
