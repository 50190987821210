import { HttpServiceHelper } from '@app/_helpers/http.helper';
import { HttpClient } from '@angular/common/http';
import { Endpoints } from '@app/_models/endpoint';
import { Injectable } from '@angular/core';
import { BucketTokenModel } from '../_models/bucketToken.model';
import { Loyalty } from '../_models/loyalty.model';

@Injectable({
  providedIn: 'root'
})
export class LoyaltyfyService {
  constructor(
    private http: HttpClient,
    private httpHelper: HttpServiceHelper
  ) {}

  getBucketToken() {
    const credentials = JSON.parse(localStorage.credentials);
    const TOKEN = `?access_token=${credentials.token}`;
    return this.http.post<BucketTokenModel>(
      this.httpHelper.baseUrl(Endpoints.BUCKETTOKEN) + TOKEN,
      {
        organizationId: '5e7c9729f4ac135b41951a02',
        bucketId: '5e7c976cf4ac1312bc951a03'
      }
    );
  }

  getLoyaltyfyUser(bucketToken: string) {
    const credentials = JSON.parse(localStorage.credentials);
    const TOKEN = `?access_token=${credentials.token}`;
    return this.http.get(
      this.httpHelper.baseUrl(Endpoints.LOYALTYUSERS) +
        `/${credentials.username}`,
      { params: { access_token: credentials.token, bucketToken: bucketToken } }
    );
  }
  getTransactionList(bucketToken: string, loyaltyId: string) {
    const credentials = JSON.parse(localStorage.credentials);
    const TOKEN = `?access_token=${credentials.token}`;
    return this.http.get<Loyalty>(
      this.httpHelper.baseUrl(Endpoints.LOYALTY_TRANSACTION_LIST),
      {
        params: {
          access_token: credentials.token,
          bucketToken: bucketToken,
          userId: loyaltyId,
          page: '1',
          pageSize: '10',
          paginate: 'true'
        }
      }
    );
  }
}
