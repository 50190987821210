export enum Endpoints {
  /* Localities */
  LOCALITIES = '/localities',
  /* Users */
  USERS = '/users',
  CHANGE_PASSWORD = '/users/change-password',
  RESEND_CODE = '/users/resendCode',
  VERIFY_ACCOUNT = '/users/auth/verifyAccount',
  LOGIN = '/users/login',
  RECOVERYPASSWORD = '/users/auth/recoveryPassword',
  CONFIRMRECOVERYPASSWORD = '/users/auth/confirmRecoveryPassword',
  REFERRALCODE = '/referral-code',
  /* Tickets */
  TICKETS = '/clientpurchases',
  USECDFI = '/tickets/getusecfdi',
  INVOICEISSUES = '/InvoiceIssues',
  AUTOMATICBILLING = '/users/billings/automaticBilling',
  /* RFCs */
  ASSOCIATIONS = '/associations',
  RFCS = '/rfcs',
  /* Loyaltyfy */
  BUCKETTOKEN = '/loyaltyfy/bucket-token',
  LOYALTYUSERS = '/loyaltyfy/users',
  LOYALTY_TRANSACTION_LIST = '/loyaltyfy/transaction-list-parsed'
}
