import { Component, OnInit, Input } from '@angular/core';
import {
  faSignInAlt,
  faUserPlus,
  faSignOutAlt,
  faUser,
  faUserAlt,
  faAngleRight,
  faFileInvoice,
  faQuestion,
  faGavel
} from '@fortawesome/free-solid-svg-icons';
import { AuthenticationService } from '../../../../core/authentication/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'dc-navbar-default',
  templateUrl: './navbar-default.component.html',
  styleUrls: ['./navbar-default.component.scss']
})
export class NavbarDefaultComponent implements OnInit {
  @Input()
  useOnlyDarkLogo: boolean;

  @Input()
  darkLinks: boolean;

  @Input()
  position: string;

  signInAlt = faSignInAlt;
  userPlus = faUserPlus;
  signOutAlt = faSignOutAlt;
  user = faUser;
  userAlt = faUserAlt;
  angleRight = faAngleRight;
  fileInvoice = faFileInvoice;
  question = faQuestion;
  gavel = faGavel;
  isLogged: boolean;
  name: string;

  constructor(private auth: AuthenticationService, private router: Router) {}

  ngOnInit() {
    this.isLogged = localStorage.credentials ? true : false;
    this.name = localStorage.name;
  }

  isRightPositioned() {
    return this.position === 'right';
  }

  logOut() {
    this.auth.logout();
    this.router.navigate(['/auth']);
  }
}
