import { NgModule } from '@angular/core';
import { SimplebarAngularModule } from 'simplebar-angular';
import { SharedModule } from '@app/shared';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { HomeRoutingModule } from './home-routing.module';

import { HomeComponent } from './components/home/home.component';
import { HomeHeadingComponent } from './components/home-heading/home-heading.component';
import { HomeFeaturesComponent } from './components/home-features/home-features.component';
import { HomeDemoComponent } from './components/home-demo/home-demo.component';
import { HomeFooterComponent } from './components/home-footer/home-footer.component';
import { SlidersModule } from '../../blocks/sliders/sliders.module';
import { NgbTabsetModule } from '@ng-bootstrap/ng-bootstrap';
import { CtaModule } from '../../blocks/cta/cta.module';
import { UsualModule } from '../../blocks/usual/usual.module';
import { FootersModule } from '../../blocks/footers/footers.module';
import { NgxTypedJsModule } from 'ngx-typed-js';
import { HomePromotionComponent } from './components/home-promotion/home-promotion.component';
import { CountUpModule } from 'countup.js-angular2';
import { SwiperModule } from 'ngx-swiper-wrapper';

@NgModule({
  imports: [
    SharedModule,
    SimplebarAngularModule,
    ScrollToModule.forRoot(),
    HomeRoutingModule,
    SlidersModule,
    NgbTabsetModule,
    CtaModule,
    UsualModule,
    FootersModule,
    NgxTypedJsModule,
    CountUpModule,
    SwiperModule
  ],
  declarations: [
    HomeComponent,
    HomeHeadingComponent,
    HomeFeaturesComponent,
    HomeDemoComponent,
    HomeFooterComponent,
    HomePromotionComponent
  ]
})
export class HomeModule {}
