import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { Credentials, CredentialsService } from './credentials.service';
import { HttpClient } from '@angular/common/http';
import { HttpServiceHelper } from '../../_helpers/http.helper';
import { Endpoints } from '@app/_models/endpoint';

export interface LoginContext {
  email: string;
  password: string;
  remember?: boolean;
}

/**
 * Provides a base for authentication workflow.
 * The login/logout methods should be replaced with proper implementation.
 */
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  constructor(
    private credentialsService: CredentialsService,
    private http: HttpClient,
    private httpHelper: HttpServiceHelper
  ) {}

  /**
   * Authenticates the user.
   * @param context The login parameters.
   * @return The user credentials.
   */
  login(context: LoginContext) {
    // Replace by proper authentication call
    return this.http
      .post(this.httpHelper.baseUrl(Endpoints.LOGIN), context)
      .pipe(
        map(response => {
          const data = {
            username: context.email,
            token: response['id'],
            userID: response['userId']
          };
          this.credentialsService.setCredentials(data, context.remember);
        })
      );
  }

  /**
   * Logs out the user and clear credentials.
   * @return True if the user was logged out successfully.
   */
  logout(): Observable<boolean> {
    // Customize credentials invalidation here
    this.credentialsService.setCredentials();
    return of(true);
  }

  recoveryPassword(email: string) {
    const PARAMS = { email: email };
    return this.http.post(
      this.httpHelper.baseUrl(Endpoints.RECOVERYPASSWORD),
      PARAMS
    );
  }

  confirmRecoveryPassword(code: number, password: string) {
    const PARAMS = { password: password, code: code };
    return this.http.post(
      this.httpHelper.baseUrl(Endpoints.CONFIRMRECOVERYPASSWORD),
      PARAMS
    );
  }
}
