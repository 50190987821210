import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dc-developer-designer',
  templateUrl: './developer-designer.component.html',
  styleUrls: ['./developer-designer.component.scss']
})
export class DeveloperDesignerComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
